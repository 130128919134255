import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const createGroup = createAsyncThunk("groups/createGroup", async (data, thunkAPI) => {
  try {
    const resp = await axios.post(`${process.env.REACT_APP_API_BASE}/api/v1/user/tunApp/groups/createGroup`, data.formData, {
      headers: {
        Authorization: "Bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
    });
    if (data?.handleSuccess) {
      data?.handleSuccess(data?.privacy);
    }
    return resp.data.group;
  } catch (error) {
    if (error.response) {
      data?.handleError(error.response.data.message);
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      data?.handleError(error.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
