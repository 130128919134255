import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const deleteEvent = createAsyncThunk("Questions/deleteEvent", async (data, thunkAPI) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE}/api/v1/user/tunApp/events/deleteEvent?postId=${data.postId}`, {
      headers: {
        Authorization: "bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
    });
    if (data.deleteConfirmation) {
      data.deleteConfirmation();
    }
    return data;
  } catch (error) {
    if (error.response) {
      data.handleFailed(error.response.data.message);
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      data.handleFailed(error.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
