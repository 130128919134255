import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const getQuestions = createAsyncThunk("Questions/getQuestions", async (filter, thunkAPI) => {
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_API_BASE}/api/v1/user/tunApp/questions/getQuestions?page=${filter.page}&limit=${filter.limit}&search=${
        filter.search || ""
      }&communityId=${filter.communityId || ""}&groupId=${filter.groupId ? filter.groupId : ""}&type=${filter.type ? filter.type : ""}&organizationId=${
        filter.organizationId ? filter.organizationId : ""
      }`,
      {
        headers: {
          Authorization: "bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
      }
    );
    localStorage.setItem("updateTime", JSON.stringify(new Date()));
    return resp.data.response;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
