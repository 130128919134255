import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const addComment = createAsyncThunk("Events/addComment", async (data, thunkAPI) => {
  try {
    let commentData = {
      postId: data._id,
      userId: data?.userDetail?._id,
      text: data.text,
    };
    const response = await axios.post(`${process.env.REACT_APP_API_BASE}/api/v1/user/tunApp/events/addcomment`, commentData, {
      headers: {
        Authorization: "bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
    });
    if (data.handleSuccess) {
      data.handleSuccess();
    }
    let obj = {
      _id: response.data.response._id,
      text: response.data.response.text,
      postId: data._id,
      user: [
        {
          _id: response.data.response.user[0]._id,
          firstName: data?.userDetail?.firstName,
          lastName: data?.userDetail?.lastName,
          userProfile: response.data.response.user[0].userProfile,
        },
      ],
      likeBy: [],
      replyBy: [],
    };
    return obj;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
