import { createSlice } from "@reduxjs/toolkit";
import { getTrending } from "./trending";
import { getDiscourse } from "./discourse";
import { getCourses } from "./courses";
import { getStudyTipsHacks } from "./getStudyTipsHacks";
import { getAdvancePlacementExams } from "./advancePlacementExams";
import { getHealthAndWellness } from "./healthAndWellness";
import { getCollegePreparation } from "./collegePreparation";
import { getCollegeAdmissions } from "./collegeAdmissions";
import { getChoosingCollege } from "./choosingCollege";
import { getStudentLife } from "./studentLife";
import { getSatAndAct } from "./getSatAndAct";
import { getMajors } from "./majors";
import { getFinancialAds } from "./financialAds";
import { getInternships } from "./internshipAndVol";
import {getScholarships} from "./scholarships"
const initialState = {
  trending: {
    isLoading: true,
    data: [],
    success: false,
    errMsg: null,
  },
  discourse: {
    isLoading: true,
    data: [],
    success: false,
    errMsg: null,
  },
  courses: {
    isLoading: true,
    data: [],
    success: false,
    errMsg: null,
  },
  studyTipsHacks: {
    isLoading: true,
    data: [],
    success: false,
    errMsg: null,
  },
  advancePlacementExams: {
    isLoading: true,
    data: [],
    success: false,
    errMsg: null,
  },
  healthAndWellness: {
    isLoading: true,
    data: [],
    success: false,
    errMsg: null,
  },
  collegePreparation: {
    isLoading: true,
    data: [],
    success: false,
    errMsg: null,
  },
  collegeAdmissions: {
    isLoading: true,
    data: [],
    success: false,
    errMsg: null,
  },
  choosingCollege: {
    isLoading: true,
    data: [],
    success: false,
    errMsg: null,
  },
  studentLife: {
    isLoading: true,
    data: [],
    success: false,
    errMsg: null,
  },
  satAndAct: {
    isLoading: true,
    data: [],
    success: false,
    errMsg: null,
  },
  majors: {
    isLoading: true,
    data: [],
    success: false,
    errMsg: null,
  },
  financialAds: {
    isLoading: true,
    data: [],
    success: false,
    errMsg: null,
  },
  interShips: {
    isLoading: true,
    data: [],
    success: false,
    errMsg: null,
  },
  scholarShips:{
    isLoading: true,
    data: [],
    success: false,
    errMsg: null,
  }
};
const HighSchoolPosts = createSlice({
  name: "HighSchoolPosts",
  initialState,
  extraReducers: {
     // Internship and volunteers
     [getScholarships.pending]: (state) => {
      state.scholarShips.isLoading = true;
      state.scholarShips.success = false;
      state.scholarShips.errMsg = true;
    },
    [getScholarships.fulfilled]: (state, action) => {
      state.scholarShips.isLoading = false;
      state.scholarShips.success = true;
      state.scholarShips.data = action.payload;
    },
    [getScholarships.rejected]: (state, action) => {
      state.scholarShips.isLoading = false;
      state.scholarShips.success = false;
      state.scholarShips.errMsg = action.payload;
    },
    // Internship and volunteers
    [getInternships.pending]: (state) => {
      state.interShips.isLoading = true;
      state.interShips.success = false;
      state.interShips.errMsg = true;
    },
    [getInternships.fulfilled]: (state, action) => {
      state.interShips.isLoading = false;
      state.interShips.success = true;
      state.interShips.data = action.payload;
    },
    [getInternships.rejected]: (state, action) => {
      state.interShips.isLoading = false;
      state.interShips.success = false;
      state.interShips.errMsg = action.payload;
    },
    // Financial Ads
    [getFinancialAds.pending]: (state) => {
      state.financialAds.isLoading = true;
      state.financialAds.success = false;
      state.financialAds.errMsg = true;
    },
    [getFinancialAds.fulfilled]: (state, action) => {
      state.financialAds.isLoading = false;
      state.financialAds.success = true;
      state.financialAds.data = action.payload;
    },
    [getFinancialAds.rejected]: (state, action) => {
      state.financialAds.isLoading = false;
      state.financialAds.success = false;
      state.financialAds.errMsg = action.payload;
    },
    // majors
    [getMajors.pending]: (state) => {
      state.majors.isLoading = true;
      state.majors.success = false;
      state.majors.errMsg = true;
    },
    [getMajors.fulfilled]: (state, action) => {
      state.majors.isLoading = false;
      state.majors.success = true;
      state.majors.data = action.payload;
    },
    [getMajors.rejected]: (state, action) => {
      state.majors.isLoading = false;
      state.majors.success = false;
      state.majors.errMsg = action.payload;
    },
    //  Student life
    [getStudentLife.pending]: (state) => {
      state.studentLife.isLoading = true;
      state.studentLife.success = false;
      state.studentLife.errMsg = true;
    },
    [getStudentLife.fulfilled]: (state, action) => {
      state.studentLife.isLoading = false;
      state.studentLife.success = true;
      state.studentLife.data = action.payload;
    },
    [getStudentLife.rejected]: (state, action) => {
      state.studentLife.isLoading = false;
      state.studentLife.success = false;
      state.studentLife.errMsg = action.payload;
    },
    //  Sat and Act
    [getSatAndAct.pending]: (state) => {
      state.satAndAct.isLoading = true;
      state.satAndAct.success = false;
      state.satAndAct.errMsg = true;
    },
    [getSatAndAct.fulfilled]: (state, action) => {
      state.satAndAct.isLoading = false;
      state.satAndAct.success = true;
      state.satAndAct.data = action.payload;
    },
    [getSatAndAct.rejected]: (state, action) => {
      state.satAndAct.isLoading = false;
      state.satAndAct.success = false;
      state.satAndAct.errMsg = action.payload;
    },
    // trending
    [getTrending.pending]: (state) => {
      state.trending.isLoading = true;
      state.trending.success = false;
      state.trending.errMsg = true;
    },
    [getTrending.fulfilled]: (state, action) => {
      state.trending.isLoading = false;
      state.trending.success = true;
      state.trending.data = action.payload;
    },
    [getTrending.rejected]: (state, action) => {
      state.trending.isLoading = false;
      state.trending.success = false;
      state.trending.errMsg = action.payload;
    },
    // Discourse
    [getDiscourse.pending]: (state) => {
      state.discourse.isLoading = true;
      state.discourse.success = false;
      state.discourse.errMsg = true;
    },
    [getDiscourse.fulfilled]: (state, action) => {
      state.discourse.isLoading = false;
      state.discourse.success = true;
      state.discourse.data = action.payload;
    },
    [getDiscourse.rejected]: (state, action) => {
      state.discourse.isLoading = false;
      state.discourse.success = false;
      state.discourse.errMsg = action.payload;
    },
    // Courses
    [getCourses.pending]: (state) => {
      state.courses.isLoading = true;
      state.courses.success = false;
      state.courses.errMsg = true;
    },
    [getCourses.fulfilled]: (state, action) => {
      state.courses.isLoading = false;
      state.courses.success = true;
      state.courses.data = action.payload;
    },
    [getCourses.rejected]: (state, action) => {
      state.courses.isLoading = false;
      state.courses.success = false;
      state.courses.errMsg = action.payload;
    },
    // study tips and hacks
    [getStudyTipsHacks.pending]: (state) => {
      state.studyTipsHacks.isLoading = true;
      state.studyTipsHacks.success = false;
      state.studyTipsHacks.errMsg = true;
    },
    [getStudyTipsHacks.fulfilled]: (state, action) => {
      state.studyTipsHacks.isLoading = false;
      state.studyTipsHacks.success = true;
      state.studyTipsHacks.data = action.payload;
    },
    [getStudyTipsHacks.rejected]: (state, action) => {
      state.studyTipsHacks.isLoading = false;
      state.studyTipsHacks.success = false;
      state.studyTipsHacks.errMsg = action.payload;
    },
    // advance placement exams
    [getAdvancePlacementExams.pending]: (state) => {
      state.advancePlacementExams.isLoading = true;
      state.advancePlacementExams.success = false;
      state.advancePlacementExams.errMsg = true;
    },
    [getAdvancePlacementExams.fulfilled]: (state, action) => {
      state.advancePlacementExams.isLoading = false;
      state.advancePlacementExams.success = true;
      state.advancePlacementExams.data = action.payload;
    },
    [getAdvancePlacementExams.rejected]: (state, action) => {
      state.advancePlacementExams.isLoading = false;
      state.advancePlacementExams.success = false;
      state.advancePlacementExams.errMsg = action.payload;
    },
    // Health and wellness
    [getHealthAndWellness.pending]: (state) => {
      state.healthAndWellness.isLoading = true;
      state.healthAndWellness.success = false;
      state.healthAndWellness.errMsg = true;
    },
    [getHealthAndWellness.fulfilled]: (state, action) => {
      state.healthAndWellness.isLoading = false;
      state.healthAndWellness.success = true;
      state.healthAndWellness.data = action.payload;
    },
    [getHealthAndWellness.rejected]: (state, action) => {
      state.healthAndWellness.isLoading = false;
      state.healthAndWellness.success = false;
      state.healthAndWellness.errMsg = action.payload;
    },
    // college preparation
    [getCollegePreparation.pending]: (state) => {
      state.collegePreparation.isLoading = true;
      state.collegePreparation.success = false;
      state.collegePreparation.errMsg = true;
    },
    [getCollegePreparation.fulfilled]: (state, action) => {
      state.collegePreparation.isLoading = false;
      state.collegePreparation.success = true;
      state.collegePreparation.data = action.payload;
    },
    [getCollegePreparation.rejected]: (state, action) => {
      state.collegePreparation.isLoading = false;
      state.collegePreparation.success = false;
      state.collegePreparation.errMsg = action.payload;
    },
    // college Admission
    [getCollegeAdmissions.pending]: (state) => {
      state.collegeAdmissions.isLoading = true;
      state.collegeAdmissions.success = false;
      state.collegeAdmissions.errMsg = true;
    },
    [getCollegeAdmissions.fulfilled]: (state, action) => {
      state.collegeAdmissions.isLoading = false;
      state.collegeAdmissions.success = true;
      state.collegeAdmissions.data = action.payload;
    },
    [getCollegeAdmissions.rejected]: (state, action) => {
      state.collegeAdmissions.isLoading = false;
      state.collegeAdmissions.success = false;
      state.collegeAdmissions.errMsg = action.payload;
    },
    //  Choosing college
    [getChoosingCollege.pending]: (state) => {
      state.choosingCollege.isLoading = true;
      state.choosingCollege.success = false;
      state.choosingCollege.errMsg = true;
    },
    [getChoosingCollege.fulfilled]: (state, action) => {
      state.choosingCollege.isLoading = false;
      state.choosingCollege.success = true;
      state.choosingCollege.data = action.payload;
    },
    [getChoosingCollege.rejected]: (state, action) => {
      state.choosingCollege.isLoading = false;
      state.choosingCollege.success = false;
      state.choosingCollege.errMsg = action.payload;
    },
  },
});

export default HighSchoolPosts.reducer;
export * from "./trending";
export * from "./discourse";
export * from "./courses";
export * from "./getStudyTipsHacks";
export * from "./advancePlacementExams";
export * from "./healthAndWellness";
export * from "./collegePreparation";
export * from "./collegeAdmissions";
export * from "./choosingCollege";
export * from "./studentLife";
export * from "./getSatAndAct";
export * from "./majors";
export * from "./financialAds";
export * from "./internshipAndVol";
export * from "./scholarships"
