import { createSlice } from "@reduxjs/toolkit";
import { getEvents } from "./getEvents";
import { addComment } from "./addComment";
import { addReplyOnComment } from "./addReplyOnComment";
import { deleteReplyOnComment } from "./deleteReply";
import { updateReplyOnComment } from "./updateReply";
import { updateComment } from "./updateComment";
import { deleteComment } from "./deleteComment";
import { addEvent } from "./addEvent";
import { UpdateEvent } from "./updateEvent";
import { deleteEvent } from "./deleteEvent";
const initialState = {
  eventsList: {
    isLoading: false,
    data: [],
    stats: {},
    success: false,
    errMsg: null,
  },
  comment: {
    isLoading: false,
    success: false,
    errMsg: null,
  },
  replyComment: {
    isLoading: false,
    success: false,
    errMsg: null,
  },
  updateReply: {
    isLoading: false,
    success: false,
    errMsg: null,
  },
  updateComment: {
    isLoading: false,
    success: false,
    errMsg: null,
  },
  addEvent: {
    isLoading: false,
    success: false,
    errMsg: null,
  },
  updateEvent: {
    isLoading: false,
    success: false,
    errMsg: null,
  },
};
const Events = createSlice({
  name: "Events",
  initialState,
  reducers: {
    addAttendingInitial: (state, action) => {
      let events = [...state.eventsList.data];
      let index = events.findIndex((item) => item._id === action.payload?.postId);
      if (action.payload.type === "attending") {
        events[index] = {
          ...events[index],
          attending: [...events[index].attending, action.payload],
        };
      }
      if (action.payload.type === "interested") {
        events[index] = {
          ...events[index],
          interested: [...events[index].interested, action.payload],
        };
      }
      if (action.payload.type === "like") {
        events[index] = {
          ...events[index],
          likes: [...events[index].likes, action.payload],
        };
      }
      state.eventsList.data = events;
    },
    undoLikeInterestedAttendingInitial: (state, action) => {
      let events = [...state.eventsList.data];
      let index = events.findIndex((item) => item._id === action.payload?.postId);
      if (action.payload.flag === "attending") {
        events[index] = {
          ...events[index],
          attending: events[index].attending?.filter((item) => item.user[0]._id !== action.payload.userId),
        };
      }
      if (action.payload.flag === "like") {
        events[index] = {
          ...events[index],
          likes: events[index].likes?.filter((item) => item.user[0]._id !== action.payload.userId),
        };
      }
      if (action.payload.flag === "interested") {
        events[index] = {
          ...events[index],
          interested: events[index].interested?.filter((item) => item.user[0]?._id !== action.payload.userId),
        };
      }
      state.eventsList.data = events;
    },
    addLikeOnCommentInitial: (state, action) => {
      let events = [...state.eventsList.data];
      let index = events.findIndex((item) => item._id === action.payload?.postId);
      let index1 = events[index].comments.findIndex((item) => item._id === action.payload.commentId);
      if (action.payload.type === "add") {
        events[index].comments[index1].likeBy = [...events[index].comments[index1].likeBy, action.payload];
      } else {
        events[index].comments[index1].likeBy = [...events[index].comments[index1].likeBy].filter((item) => item.userId !== action.payload.userId);
      }
      state.eventsList.data = events;
    },
  },
  extraReducers: {
    // add event
    [addEvent.pending]: (state) => {
      state.addEvent.isLoading = true;
      state.addEvent.success = false;
      state.addEvent.errMsg = null;
    },
    [addEvent.fulfilled]: (state, action) => {
      state.addEvent.isLoading = false;
      state.addEvent.success = true;
      let events = [...state.eventsList.data];
      events.unshift(action.payload);
      state.eventsList.data = events;
      state.eventsList.stats.totalDoc = state.eventsList.stats.totalDocs + 1;
    },
    [addEvent.rejected]: (state, action) => {
      state.addEvent.isLoading = false;
      state.addEvent.success = false;
      state.addEvent.errMsg = action.payload;
    },
    // update Event
    [UpdateEvent.pending]: (state) => {
      state.updateEvent.isLoading = true;
      state.updateEvent.success = false;
      state.updateEvent.errMsg = null;
    },
    [UpdateEvent.fulfilled]: (state, action) => {
      state.updateEvent.isLoading = false;
      state.updateEvent.success = true;
      let events = [...state.eventsList.data];
      let index = events.findIndex((item) => item._id === action.payload?.postId);
      events[index] = { ...events[index], ...action.payload };
      state.eventsList.data = events;
    },
    [UpdateEvent.rejected]: (state, action) => {
      state.updateEvent.isLoading = false;
      state.updateEvent.success = false;
      state.updateEvent.errMsg = action.payload;
    },
    // events
    [getEvents.pending]: (state) => {
      state.eventsList.isLoading = true;
      state.eventsList.success = false;
      state.eventsList.errMsg = null;
    },
    [getEvents.fulfilled]: (state, action) => {
      state.eventsList.isLoading = false;
      state.eventsList.success = true;
      state.eventsList.data = action.payload.docs;
      state.eventsList.stats = { ...action.payload, docs: null };
    },
    [getEvents.rejected]: (state, action) => {
      state.eventsList.isLoading = false;
      state.eventsList.success = false;
      state.eventsList.errMsg = action.payload;
    },
    // add comment
    [addComment.pending]: (state) => {
      state.comment.isLoading = true;
      state.comment.success = false;
      state.comment.errMsg = null;
    },
    [addComment.fulfilled]: (state, action) => {
      state.comment.isLoading = false;
      state.comment.success = true;
      let events = [...state.eventsList.data];
      let index = events.findIndex((item) => item._id === action.payload?.postId);
      events[index].comments.unshift(action.payload);
      state.eventsList.data = events;
    },
    [addComment.rejected]: (state, action) => {
      state.comment.isLoading = false;
      state.comment.success = false;
      state.comment.errMsg = action.payload;
    },
    // add reply on comment
    [addReplyOnComment.pending]: (state) => {
      state.replyComment.isLoading = true;
      state.replyComment.success = false;
      state.replyComment.errMsg = null;
    },
    [addReplyOnComment.fulfilled]: (state, action) => {
      state.replyComment.isLoading = false;
      state.replyComment.success = true;
      let events = [...state.eventsList.data];
      let index = events.findIndex((item) => item._id === action.payload?.postId);
      events[index].comments.filter((item) => item._id === action.payload.commentId)[0].replyBy.unshift(action.payload);
      state.eventsList.data = events;
    },
    [addReplyOnComment.rejected]: (state, action) => {
      state.replyComment.isLoading = false;
      state.replyComment.success = false;
      state.replyComment.errMsg = action.payload;
    },
    // delete Reply On Comment
    [deleteReplyOnComment.fulfilled]: (state, action) => {
      let events = [...state.eventsList.data];
      let index = events.findIndex((item) => item._id === action.payload?.postId);
      const commentData = events[index].comments;
      const CommentIndex = commentData.findIndex((item) => item._id === action.payload.commentId);
      const findReplyIndex = commentData[CommentIndex].replyBy.findIndex((it) => it._id === action.payload.replyId);
      commentData[CommentIndex].replyBy.splice(findReplyIndex, 1);
      state.eventsList.data = events;
    },
    // update reply on comment
    [updateReplyOnComment.pending]: (state) => {
      state.updateReply.isLoading = true;
      state.updateReply.success = false;
      state.updateReply.errMsg = null;
    },
    [updateReplyOnComment.fulfilled]: (state, action) => {
      state.updateReply.isLoading = false;
      state.updateReply.success = true;
      let events = [...state.eventsList.data];
      let index = events.findIndex((item) => item._id === action.payload?.postId);
      const findComment2 = events[index].comments.filter((it) => it._id === action.payload.commentId)[0];
      const findReplyIndex2 = findComment2.replyBy.findIndex((it) => it._id === action.payload.replyId);
      findComment2.replyBy[findReplyIndex2].text = action.payload.text;
      state.eventsList.data = events;
    },
    [updateReplyOnComment.rejected]: (state, action) => {
      state.updateReply.isLoading = false;
      state.updateReply.success = false;
      state.updateReply.errMsg = action.payload;
    },
    // update comment
    [updateComment.pending]: (state) => {
      state.updateComment.isLoading = true;
      state.updateComment.success = false;
      state.updateComment.errMsg = null;
    },
    [updateComment.fulfilled]: (state, action) => {
      state.updateComment.isLoading = false;
      state.updateComment.success = true;
      let events = [...state.eventsList.data];
      let index = events.findIndex((item) => item._id === action.payload?.postId);
      let findCommentIndex = events[index].comments.findIndex((it) => it._id === action.payload.commentId);
      events[index].comments[findCommentIndex].text = action.payload.text;
      state.eventsList.data = events;
    },
    [updateComment.rejected]: (state, action) => {
      state.updateComment.isLoading = false;
      state.updateComment.success = false;
      state.updateComment.errMsg = action.payload;
    },
    // delete comment
    [deleteComment.fulfilled]: (state, action) => {
      let events = [...state.eventsList.data];
      let index = events.findIndex((item) => item._id === action.payload?.postId);
      const commentData = events[index].comments;
      const deleteCommentIndex = commentData.findIndex((item) => item._id === action.payload.commentId);
      commentData.splice(deleteCommentIndex, 1);
      state.eventsList.data = events;
    },
    // delete Event
    [deleteEvent.fulfilled]: (state, action) => {
      let events = [...state.eventsList.data];
      state.eventsList.data = events.filter((item) => item._id !== action.payload.postId);
    },
  },
});
export const { addAttendingInitial, undoLikeInterestedAttendingInitial, addLikeOnCommentInitial } = Events.actions;
export default Events.reducer;
export * from "./getEvents";
export * from "./addAttending";
export * from "./undoLikeInterestedAttending";
export * from "./addInterested";
export * from "./addLike";
export * from "./addComment";
export * from "./addLikeOnComment";
export * from "./dislikeComment";
export * from "./addReplyOnComment";
export * from "./deleteReply";
export * from "./updateReply";
export * from "./updateComment";
export * from "./deleteComment";
export * from "./addEvent";
export * from "./updateEvent";
export * from "./deleteEvent";
