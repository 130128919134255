import axios from 'axios';

export const setAuthToken = token => {
    if(token) {
        axios.defaults.headers.common['Authorization'] = `${token}`;
    }
    else {
        axios.defaults.headers.common['Authorization'] = '';
        delete axios.defaults.headers.common['Authorization'];

    }
}