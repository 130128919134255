import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const getGradeLevels = createAsyncThunk("globalData/getGradeLevels", async (data, thunkAPI) => {
  try {
    const resp = await axios.get(`${process.env.REACT_APP_API_BASE}/api/v1/user/gradeLevels/getGradeLevels?limit=1000&status=Active&page=1`, data, {
      headers: {
        Authorization: "Bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
    });
    return resp.data?.response;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});