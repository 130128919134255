import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import userAuth from "./auth";
import HighSchoolPosts from "./schoolPosts";
import globalData from "./global/index";
import collegePosts from "./collegePosts";
import Questions from "./questions";
import Events from "./events"
import chat from "./chat"
import groups from "./groups"
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const rootReducer = combineReducers({
  auth: userAuth,
  schoolPosts: HighSchoolPosts,
  collegePosts: collegePosts,
  global: globalData,
  questions: Questions,
  events:Events,
  chat,
  groups
});
const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore(
  {
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "production",
  }
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
export const persistor = persistStore(store);
