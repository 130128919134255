import { createSlice } from "@reduxjs/toolkit";
import { searchGroups } from "./searchGroup";
import { getNotificationCount } from "./getNotificationCount";
import { getGroupDetail } from "./getGroupDetail";
import { getCreatedGroups } from "./getCreatedGroups";
import { getJoinedGroups } from "./getJoinedGroups";
import { updateGroupPrivacy } from "./updateGroupPrivacy";
import { joinGroup } from "./joinGroup";
import { getDiscoverGroups } from "./discoverGroups";
import { getNotificationsGroups } from "./getGroupNotification";
import { createGroup } from "./createGroups";
import { acceptOrRejectGroupRequest } from "./acceptRejectGroupRequest";
import { removeGroupMember } from "./removeMember";
const initialState = {
  search: {
    isLoading: false,
    data: [],
    errMsg: null,
    success: false,
  },
  notificationCount: {
    count: 0,
    isLoading: false,
    errMsg: null,
    success: false,
  },
  groupDetail: {
    isLoading: false,
    errMsg: null,
    success: false,
    data: {},
  },
  createdGroups: {
    isLoading: false,
    errMsg: null,
    success: false,
    data: [],
  },
  joinedGroups: {
    isLoading: false,
    errMsg: null,
    success: false,
    data: {},
  },
  discoverGroups: {
    isLoading: false,
    errMsg: null,
    success: false,
    data: {},
  },
  notifications: {
    isLoading: false,
    errMsg: null,
    success: false,
    data: [],
  },
};

const groups = createSlice({
  name: "groups",
  initialState,
  reducers: {
    handleRemoveGroup: (state, action) => {
      if (action.payload) {
        let discoverGroupsNew = [...state.discoverGroups.data.docs];
        let index = discoverGroupsNew.findIndex((item) => item._id === action.payload);
        discoverGroupsNew.splice(index, 1);
        state.discoverGroups.data.docs = discoverGroupsNew;
      }
    },
  },
  extraReducers: {
    // get group detail
    [getGroupDetail.pending]: (state) => {
      state.groupDetail.isLoading = true;
      state.groupDetail.errMsg = null;
    },
    [getGroupDetail.fulfilled]: (state, action) => {
      state.groupDetail.isLoading = false;
      state.groupDetail.errMsg = null;
      state.groupDetail.success = true;
      state.groupDetail.data = action.payload;
    },
    [getGroupDetail.rejected]: (state, action) => {
      state.groupDetail.isLoading = false;
      state.groupDetail.errMsg = action.payload;
      state.groupDetail.success = false;
    },
    // search groups
    [searchGroups.pending]: (state) => {
      state.search.isLoading = true;
      state.search.errMsg = null;
    },
    [searchGroups.fulfilled]: (state, action) => {
      state.search.isLoading = false;
      state.search.errMsg = null;
      state.search.success = true;
      state.search.data = action.payload;
    },
    [searchGroups.rejected]: (state, action) => {
      state.search.isLoading = false;
      state.search.errMsg = action.payload;
      state.search.success = false;
    },
    // notification count
    [getNotificationCount.pending]: (state) => {
      state.search.isLoading = true;
      state.search.errMsg = null;
    },
    [getNotificationCount.fulfilled]: (state, action) => {
      state.search.isLoading = false;
      state.search.errMsg = null;
      state.search.success = true;
      state.search.count = action.payload;
    },
    [getNotificationCount.rejected]: (state, action) => {
      state.search.isLoading = false;
      state.search.errMsg = action.payload;
      state.search.success = false;
    },
    // get created groups getCreatedGroups
    [getCreatedGroups.pending]: (state) => {
      state.createdGroups.isLoading = true;
      state.createdGroups.errMsg = null;
    },
    [getCreatedGroups.fulfilled]: (state, action) => {
      state.createdGroups.isLoading = false;
      state.createdGroups.errMsg = null;
      state.createdGroups.success = true;
      state.createdGroups.data = action.payload;
    },
    [getCreatedGroups.rejected]: (state, action) => {
      state.createdGroups.isLoading = false;
      state.createdGroups.errMsg = action.payload;
      state.createdGroups.success = false;
    },
    // get joined groups
    [getJoinedGroups.pending]: (state) => {
      state.joinedGroups.isLoading = true;
      state.joinedGroups.errMsg = null;
    },
    [getJoinedGroups.fulfilled]: (state, action) => {
      state.joinedGroups.isLoading = false;
      state.joinedGroups.errMsg = null;
      state.joinedGroups.success = true;
      state.joinedGroups.data = action.payload;
    },
    [getJoinedGroups.rejected]: (state, action) => {
      state.joinedGroups.isLoading = false;
      state.joinedGroups.errMsg = action.payload;
      state.joinedGroups.success = false;
    },
    // update group privacy
    [updateGroupPrivacy.fulfilled]: (state, action) => {
      let groupsData = [...state.createdGroups.data];
      let groupDetail = groupsData.find((item) => item._id === action.payload.groupId);
      groupDetail.privacy = action.payload.type;
      state.createdGroups.data = groupsData;
    },
    // update group privacy
    [joinGroup.fulfilled]: (state, action) => {
      if (action.payload.type === "discover") {
        let discoverGroupsNew = [...state.discoverGroups.data.docs];
        let index = discoverGroupsNew.findIndex((item) => item._id === action.payload.groupId);
        if (action.payload.privacy === "Public") {
          discoverGroupsNew.splice(index, 1);
        } else {
          discoverGroupsNew[index].requestedMembers.push(action.payload?.userId);
        }
        state.discoverGroups.data.docs = discoverGroupsNew;
      } else {
        let groupsData = [...state.search.data];
        let groupDetail = groupsData.find((item) => item._id === action.payload.groupId);
        if (action.payload.privacy === "Public") {
          groupDetail.members = [...groupDetail.members, action.payload.userId];
        } else {
          groupDetail.requestedMembers = [...groupDetail.requestedMembers, action.payload.userId];
        }
        state.search.data = groupsData;
      }
    },
    // discover groups
    [getDiscoverGroups.pending]: (state) => {
      state.discoverGroups.isLoading = true;
      state.discoverGroups.errMsg = null;
    },
    [getDiscoverGroups.fulfilled]: (state, action) => {
      state.discoverGroups.isLoading = false;
      state.discoverGroups.errMsg = null;
      state.discoverGroups.success = true;
      state.discoverGroups.data = action.payload;
    },
    [getDiscoverGroups.rejected]: (state, action) => {
      state.discoverGroups.isLoading = false;
      state.discoverGroups.errMsg = action.payload;
      state.discoverGroups.success = false;
    },
    // discover groups
    [getNotificationsGroups.pending]: (state) => {
      state.notifications.isLoading = true;
      state.notifications.errMsg = null;
    },
    [getNotificationsGroups.fulfilled]: (state, action) => {
      state.notifications.isLoading = false;
      state.notifications.errMsg = null;
      state.notifications.success = true;
      state.notifications.data = action.payload;
    },
    [getNotificationsGroups.rejected]: (state, action) => {
      state.notifications.isLoading = false;
      state.notifications.errMsg = action.payload;
      state.notifications.success = false;
    },
    // create groups
    [createGroup.fulfilled]: (state, action) => {
      let groupsData = [...state.createdGroups.data];
      groupsData.unshift(action.payload);
      state.createdGroups.data = groupsData;
    },
    // accept or reject the group request  acceptOrRejectGroupRequest
    [acceptOrRejectGroupRequest.fulfilled]: (state, action) => {
      let createdGroupsNew = [...state.createdGroups.data];
      let index = createdGroupsNew.findIndex((item) => item._id === action.payload.groupId);
      const requestedIndex = createdGroupsNew[index].requestedMembers.findIndex((item) => item._id === action.payload.requestedId);
      createdGroupsNew[index].requestedMembers.splice(requestedIndex, 1);
      state.createdGroups.data = createdGroupsNew;
    },
    //removeGroupMember
    [removeGroupMember.fulfilled]: (state, action) => {
      let createdGroupsNew = [...state.createdGroups.data];
      let index = createdGroupsNew.findIndex((item) => item._id === action.payload.groupId);
      const requestedIndex = createdGroupsNew[index].members.findIndex((item) => item._id === action.payload.memberId);
      createdGroupsNew[index].members.splice(requestedIndex, 1);
      state.createdGroups.data = createdGroupsNew;
    },
  },
});

export const { handleRemoveGroup } = groups.actions;
export default groups.reducer;
export * from "./searchGroup";
export * from "./getNotificationCount";
export * from "./getGroupDetail";
export * from "./joinGroup";
export * from "./getCreatedGroups";
export * from "./getJoinedGroups";
export * from "./updateGroupPrivacy";
export * from "./sendInvitations";
export * from "./discoverGroups";
export * from "./getGroupNotification";
export * from "./createGroups";
export * from "./acceptRejectGroupRequest";
export * from "./removeMember";
