import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const updateComment = createAsyncThunk("Events/updateComment", async (data, thunkAPI) => {
  try {
    let commentData = {
      postId: data.postId,
      text: data.text,
      commentId: data.commentId,
    };
    const response = await axios.post(`${process.env.REACT_APP_API_BASE}/api/v1/user/tunApp/questions/updateComment`, commentData, {
      headers: {
        Authorization: "bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
    });
    if (data.commentUpdateSuccess) {
      data.commentUpdateSuccess();
    }
    return commentData;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
