import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const deleteReplyOnComment = createAsyncThunk("Events/deleteReplyOnComment", async (data, thunkAPI) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE}/api/v1/user/tunApp/questions/deleteReply`,
      {
        commentId: data.commentId,
        replyId: data.replyId,
      },
      {
        headers: {
          Authorization: "bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
      }
    );
    if (data.deleteConfirmation) {
      data.deleteConfirmation();
    }
    return {
      commentId: data.commentId,
      replyId: data.replyId,
      postId: data.postId,
    };
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
