import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { addLikeOnCommentInitial } from "./index";
export const addLikeOnComment = createAsyncThunk("Events/addLikeOnComment", async (data, thunkAPI) => {
  try {
    let commentData = {
      commentId: data._id,
      userId: data?.userDetail?._id,
      userName: data?.userDetail?.firstName + " " + data?.userDetail?.lastName,
    };
    let obj = {
      ...commentData,
    postId: data.postId,
    userId: data.userDetail?._id,
    type:"add"
  };
  thunkAPI.dispatch(addLikeOnCommentInitial(obj));
    const response = await axios.post(`${process.env.REACT_APP_API_BASE}/api/v1/user/tunApp/questions/addLikeOnComment`, commentData, {
      headers: {
        Authorization: "bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
    });
  
    return response;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
