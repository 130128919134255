import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const removeGroupMember = createAsyncThunk("groups/removeGroupMember", async (data, thunkAPI) => {
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_API_BASE}/api/v1/user/tunApp/groups/removerMember?userId=${data?.userId || ""}&groupId=${
        data?.groupId || ""
      }&memberId=${data?.memberId || ""}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
      }
    );
    if (data?.handleSuccess) {
      data?.handleSuccess(data?.privacy);
    }
    return { groupId: data?.groupId, memberId: data?.memberId };
  } catch (error) {
    if (error.response) {
      data?.handleError(error.response.data.message);
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      data?.handleError(error.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
