import { createSlice } from "@reduxjs/toolkit";
import { getTrending } from "./trending";
import { getStudyTipsHacks } from "./getStudyTipsHack";
import { getHealthAndWellness } from "./healthAndWellness";
import { getStudentLife } from "./studentLife";
import { getCareerAdvice } from "./careerAdvice";
import { getChoosingMajor } from "./choosingMajor";
import { getOnlineClasses } from "./onlineClasses";
import { getFeaturedScholarships } from "./sholarships";
import { getFinancialAds } from "./financialAds";
import { getStudentLoans } from "./studentLoans";
import { getStudentBudget } from "./studentBudget";
import { getStudyAbroad } from "./studyAbroad";
import { getSchoolGrad } from "./schoolGrad";
import { getDiscourse } from "./discourse";
const initialState = {
  trending: {
    isLoading: false,
    data: [],
    success: false,
    errMsg: null,
  },
  studyTips: {
    isLoading: false,
    data: [],
    success: false,
    errMsg: null,
  },
  healthAndWellness: {
    isLoading: false,
    data: [],
    success: false,
    errMsg: null,
  },
  studentLife: {
    isLoading: false,
    data: [],
    success: false,
    errMsg: null,
  },
  careerAdvice: {
    isLoading: false,
    data: [],
    success: false,
    errMsg: null,
  },
  choosingMajor: {
    isLoading: false,
    data: [],
    success: false,
    errMsg: null,
  },
  onlineClasses: {
    isLoading: false,
    data: [],
    success: false,
    errMsg: null,
  },
  scholarships: {
    isLoading: false,
    data: [],
    success: false,
    errMsg: null,
  },
  financialAds: {
    isLoading: false,
    data: [],
    success: false,
    errMsg: null,
  },
  studentLoans: {
    isLoading: false,
    data: [],
    success: false,
    errMsg: null,
  },
  studentBudget: {
    isLoading: false,
    data: [],
    success: false,
    errMsg: null,
  },
  studyAbroad: {
    isLoading: false,
    data: [],
    success: false,
    errMsg: null,
  },
  schoolGrad: {
    isLoading: false,
    data: [],
    success: false,
    errMsg: null,
  },
  discourse: {
    isLoading: false,
    data: [],
    success: false,
    errMsg: null,
  },
};

const collegePosts = createSlice({
  name: "collegePosts",
  initialState,
  extraReducers: {
    // discourse
    [getDiscourse.pending]: (state) => {
      state.discourse.isLoading = true;
      state.discourse.success = false;
      state.discourse.errMsg = true;
    },
    [getDiscourse.fulfilled]: (state, action) => {
      state.discourse.isLoading = false;
      state.discourse.success = true;
      state.discourse.data = action.payload;
    },
    [getDiscourse.rejected]: (state, action) => {
      state.discourse.isLoading = false;
      state.discourse.success = false;
      state.discourse.errMsg = action.payload;
    },
    // school Grad
    [getSchoolGrad.pending]: (state) => {
      state.schoolGrad.isLoading = true;
      state.schoolGrad.success = false;
      state.schoolGrad.errMsg = true;
    },
    [getSchoolGrad.fulfilled]: (state, action) => {
      state.schoolGrad.isLoading = false;
      state.schoolGrad.success = true;
      state.schoolGrad.data = action.payload;
    },
    [getSchoolGrad.rejected]: (state, action) => {
      state.schoolGrad.isLoading = false;
      state.schoolGrad.success = false;
      state.schoolGrad.errMsg = action.payload;
    },
    // study abroad
    [getStudyAbroad.pending]: (state) => {
      state.studyAbroad.isLoading = true;
      state.studyAbroad.success = false;
      state.studyAbroad.errMsg = true;
    },
    [getStudyAbroad.fulfilled]: (state, action) => {
      state.studyAbroad.isLoading = false;
      state.studyAbroad.success = true;
      state.studyAbroad.data = action.payload;
    },
    [getStudyAbroad.rejected]: (state, action) => {
      state.studyAbroad.isLoading = false;
      state.studyAbroad.success = false;
      state.studyAbroad.errMsg = action.payload;
    },
    // student Budget
    [getStudentBudget.pending]: (state) => {
      state.studentBudget.isLoading = true;
      state.studentBudget.success = false;
      state.studentBudget.errMsg = true;
    },
    [getStudentBudget.fulfilled]: (state, action) => {
      state.studentBudget.isLoading = false;
      state.studentBudget.success = true;
      state.studentBudget.data = action.payload;
    },
    [getStudentBudget.rejected]: (state, action) => {
      state.studentBudget.isLoading = false;
      state.studentBudget.success = false;
      state.studentBudget.errMsg = action.payload;
    },
    // student loans
    [getStudentLoans.pending]: (state) => {
      state.studentLoans.isLoading = true;
      state.studentLoans.success = false;
      state.studentLoans.errMsg = true;
    },
    [getStudentLoans.fulfilled]: (state, action) => {
      state.studentLoans.isLoading = false;
      state.studentLoans.success = true;
      state.studentLoans.data = action.payload;
    },
    [getStudentLoans.rejected]: (state, action) => {
      state.studentLoans.isLoading = false;
      state.studentLoans.success = false;
      state.studentLoans.errMsg = action.payload;
    },
    // Financial Ads
    [getFinancialAds.pending]: (state) => {
      state.financialAds.isLoading = true;
      state.financialAds.success = false;
      state.financialAds.errMsg = true;
    },
    [getFinancialAds.fulfilled]: (state, action) => {
      state.financialAds.isLoading = false;
      state.financialAds.success = true;
      state.financialAds.data = action.payload;
    },
    [getFinancialAds.rejected]: (state, action) => {
      state.financialAds.isLoading = false;
      state.financialAds.success = false;
      state.financialAds.errMsg = action.payload;
    },
    // Featured scholarships
    [getFeaturedScholarships.pending]: (state) => {
      state.scholarships.isLoading = true;
      state.scholarships.success = false;
      state.scholarships.errMsg = true;
    },
    [getFeaturedScholarships.fulfilled]: (state, action) => {
      state.scholarships.isLoading = false;
      state.scholarships.success = true;
      state.scholarships.data = action.payload;
    },
    [getFeaturedScholarships.rejected]: (state, action) => {
      state.scholarships.isLoading = false;
      state.scholarships.success = false;
      state.scholarships.errMsg = action.payload;
    },
    // choosing major
    [getOnlineClasses.pending]: (state) => {
      state.onlineClasses.isLoading = true;
      state.onlineClasses.success = false;
      state.onlineClasses.errMsg = true;
    },
    [getOnlineClasses.fulfilled]: (state, action) => {
      state.onlineClasses.isLoading = false;
      state.onlineClasses.success = true;
      state.onlineClasses.data = action.payload;
    },
    [getOnlineClasses.rejected]: (state, action) => {
      state.onlineClasses.isLoading = false;
      state.onlineClasses.success = false;
      state.onlineClasses.errMsg = action.payload;
    },
    // choosing major
    [getChoosingMajor.pending]: (state) => {
      state.choosingMajor.isLoading = true;
      state.choosingMajor.success = false;
      state.choosingMajor.errMsg = true;
    },
    [getChoosingMajor.fulfilled]: (state, action) => {
      state.choosingMajor.isLoading = false;
      state.choosingMajor.success = true;
      state.choosingMajor.data = action.payload;
    },
    [getChoosingMajor.rejected]: (state, action) => {
      state.choosingMajor.isLoading = false;
      state.choosingMajor.success = false;
      state.choosingMajor.errMsg = action.payload;
    },
    // career advice
    [getCareerAdvice.pending]: (state) => {
      state.careerAdvice.isLoading = true;
      state.careerAdvice.success = false;
      state.careerAdvice.errMsg = true;
    },
    [getCareerAdvice.fulfilled]: (state, action) => {
      state.careerAdvice.isLoading = false;
      state.careerAdvice.success = true;
      state.careerAdvice.data = action.payload;
    },
    [getCareerAdvice.rejected]: (state, action) => {
      state.careerAdvice.isLoading = false;
      state.careerAdvice.success = false;
      state.careerAdvice.errMsg = action.payload;
    },
    // student life
    [getStudentLife.pending]: (state) => {
      state.studentLife.isLoading = true;
      state.studentLife.success = false;
      state.studentLife.errMsg = true;
    },
    [getStudentLife.fulfilled]: (state, action) => {
      state.studentLife.isLoading = false;
      state.studentLife.success = true;
      state.studentLife.data = action.payload;
    },
    [getStudentLife.rejected]: (state, action) => {
      state.studentLife.isLoading = false;
      state.studentLife.success = false;
      state.studentLife.errMsg = action.payload;
    },
    // Health and wellness
    [getHealthAndWellness.pending]: (state) => {
      state.healthAndWellness.isLoading = true;
      state.healthAndWellness.success = false;
      state.healthAndWellness.errMsg = true;
    },
    [getHealthAndWellness.fulfilled]: (state, action) => {
      state.healthAndWellness.isLoading = false;
      state.healthAndWellness.success = true;
      state.healthAndWellness.data = action.payload;
    },
    [getHealthAndWellness.rejected]: (state, action) => {
      state.healthAndWellness.isLoading = false;
      state.healthAndWellness.success = false;
      state.healthAndWellness.errMsg = action.payload;
    },
    // study tips and hack
    [getStudyTipsHacks.pending]: (state) => {
      state.studyTips.isLoading = true;
      state.studyTips.success = false;
      state.studyTips.errMsg = true;
    },
    [getStudyTipsHacks.fulfilled]: (state, action) => {
      state.studyTips.isLoading = false;
      state.studyTips.success = true;
      state.studyTips.data = action.payload;
    },
    [getStudyTipsHacks.rejected]: (state, action) => {
      state.studyTips.isLoading = false;
      state.studyTips.success = false;
      state.studyTips.errMsg = action.payload;
    },
    // trending
    [getTrending.pending]: (state) => {
      state.trending.isLoading = true;
      state.trending.success = false;
      state.trending.errMsg = true;
    },
    [getTrending.fulfilled]: (state, action) => {
      state.trending.isLoading = false;
      state.trending.success = true;
      state.trending.data = action.payload;
    },
    [getTrending.rejected]: (state, action) => {
      state.trending.isLoading = false;
      state.trending.success = false;
      state.trending.errMsg = action.payload;
    },
  },
});

export default collegePosts.reducer;
export * from "./trending";
export * from "./getStudyTipsHack";
export * from "./healthAndWellness";
export * from "./studentLife";
export * from "./careerAdvice";
export * from "./choosingMajor";
export * from "./onlineClasses";
export * from "./sholarships";
export * from "./financialAds";
export * from "./studentLoans";
export * from "./studentBudget";
export * from "./studyAbroad";
export * from "./schoolGrad";
export * from "./discourse";
