import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const addReplyOnComment = createAsyncThunk("Events/addReplyOnComment", async (data, thunkAPI) => {
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE}/api/v1/user/tunApp/questions/replyBy`, data, {
      headers: {
        Authorization: "bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
    });
    if (data.handleSuccessReply) {
      data.handleSuccessReply();
    }
    return { ...response.data.response.replyBy[response.data.response.replyBy.length - 1], commentId: data.commentId, postId: data.postId };
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
