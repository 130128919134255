import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { undoLikeInterestedAttendingInitial } from "./index";
export const undoLikeInterestedAttending = createAsyncThunk("Events/undoLikeInterestedAttending", async (data, thunkAPI) => {
  try {

    let Data = {
        postId: data._id,
        userId: data.userDetail?._id,
        flag:data.flag,
      };
    thunkAPI.dispatch(undoLikeInterestedAttendingInitial(Data));
    const response = await axios.post(`${process.env.REACT_APP_API_BASE}/api/v1/user/tunApp/events/undoLikeInterestedAttending`, Data, {
      headers: {
        Authorization: "bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
