import { createSlice } from "@reduxjs/toolkit";
import { getUnreadChatCount } from "./unreadChatCount";

const initialState = {
  chatCount: {
    count: 0,
  },
};

const chat = createSlice({
  name: "chat",
  initialState,
  extraReducers: {
    // get unread chat count
    [getUnreadChatCount.fulfilled]: (state, action) => {
      state.chatCount.isLoading = false;
      state.chatCount.errMsg = null;
      state.chatCount.count = action.payload;
    },
  },
});

export default chat.reducer;
export * from "./unreadChatCount";
