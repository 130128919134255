import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const userSignup = createAsyncThunk("auth/userSignup", async (data, thunkAPI) => {
  try {
    const resp = await axios.post(`${process.env.REACT_APP_API_BASE}/api/v1/user/tunApp/signup`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    data.handleSuccess(data);
    return resp.data?.data?.userDetails;
  } catch (error) {
    if (error.response) {
      data.handleError(error.response.data.message);
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      data.handleError(error.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
