import { createSlice } from "@reduxjs/toolkit";
import { userLogin } from "./login";
import { getUserDetail } from "./getUserDetail";
import { userSignup } from "./signup";
import { updateProfile } from "./updateUserProfile";
import { updateUserInfo } from "./updateUserInfo";
const initialState = {
  isAuthenticated: false,
  userDetail: null,
  otherDetail: {
    userType: "",
    communityType: "highschool",
    CommunityUrl: "",
    communityId: "",
    communityName: "",
    preferences: [],
    gradeLevels: [],
  },
  isLoading: false,
  errMsg: null,
  signup: {
    isLoading: false,
    success: false,
    errMsg: null,
  },
  login: {
    isLoading: false,
    success: false,
    errMsg: null,
  },
  userStats: {
    createdGroupCount: 0,
    eventCount: 0,
    joinedGroupCount: 0,
    questionCount: 0,
    stuffCount: 0,
  },
};

const userAuth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuthState: (state, action) => {
      state.isAuthenticated = false;
      state.userDetail = null;
      state.otherDetail = {
        communityType: "highschool",
        userType: "",
        CommunityUrl: "",
        communityId: "",
        communityName: "",
        preferences: [],
        gradeLevels: [],
      };
      state.isLoading = false;
      state.errMsg = null;
    },
    updateAuthState: (state, action) => {
      if (action.payload.isAuthenticated) {
        state.isAuthenticated = action.payload.isAuthenticated;
      }
      if (action.payload.userDetail) {
        state.userDetail = { ...state.userDetail, ...action.payload.userDetail };
      }
      if (action.payload.otherDetail) {
        state.otherDetail = { ...state.otherDetail, ...action.payload.otherDetail };
      }
    },
    handleUpdatePreferences: (state, action) => {
      state.otherDetail.preferences = action.payload;
    },
    handleUpdateGrades: (state, action) => {
      state.otherDetail.gradeLevels = action.payload;
    },
    handleResetLoginSignup: (state, action) => {
      state.signup.isLoading = false;
      state.signup.success = false;
      state.signup.errMsg = null;
      state.login.isLoading = false;
      state.login.success = false;
      state.login.errMsg = null;
    },
  },
  extraReducers: {
    [userLogin.pending]: (state) => {
      state.login.isLoading = true;
      state.login.errMsg = null;
    },
    [userLogin.fulfilled]: (state, action) => {
      state.login.isLoading = false;
      state.isAuthenticated = true;
      state.userDetail = action.payload;
      state.otherDetail = { ...state.otherDetail, ...action.payload };
    },
    [userLogin.rejected]: (state, action) => {
      state.login.isLoading = false;
      state.isAuthenticated = false;
      state.login.errMsg = action.payload;
    },
    [getUserDetail.pending]: (state) => {
      state.isLoading = true;
    },
    [getUserDetail.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = true;
      state.userDetail = { ...state.userDetail, ...action.payload.user };
      state.otherDetail = { ...state.otherDetail, ...action.payload };
      state.userStats = {
        createdGroupCount: action.payload?.createdGroupCount,
        eventCount: action.payload?.eventCount,
        joinedGroupCount: action.payload?.joinedGroupCount,
        questionCount: action.payload?.questionCount,
        stuffCount: action.payload?.stuffCount,
      };
    },
    [getUserDetail.rejected]: (state, action) => {
      state.isLoading = false;
      state.isAuthenticated = false;
      state.errMsg = action.payload;
    },
    // update user profile
    [updateProfile.fulfilled]: (state, action) => {
      if (state?.userDetail?.userProfile) {
        state.userDetail.userProfile = action.payload?.userProfile?.url;
      }
    },
    // update user info
    [updateUserInfo.fulfilled]: (state, action) => {
      if (state?.userDetail) {
        state.userDetail = { ...state.userDetail, ...action.payload };
      }
    },
    // user signup
    [userSignup.pending]: (state) => {
      state.signup.isLoading = true;
      state.signup.success = false;
      state.signup.errMsg = null;
    },
    [userSignup.fulfilled]: (state, action) => {
      state.signup.isLoading = false;
      state.signup.success = true;
      state.signup.errMsg = null;
    },
    [userSignup.rejected]: (state, action) => {
      state.signup.isLoading = false;
      state.signup.success = false;
      state.signup.errMsg = action.payload;
    },
  },
});

export const { resetAuthState, updateAuthState, handleUpdatePreferences, handleUpdateGrades, handleResetLoginSignup } = userAuth.actions;

export default userAuth.reducer;

export * from "./login";
export * from "./getUserDetail";
export * from "./logout";
export * from "./getGradeLevels";
export * from "./getPreferences";
export * from "./signup";
