import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const deleteQuestion = createAsyncThunk("Questions/deleteQuestion", async (data, thunkAPI) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE}/api/v1/user/tunApp/questions/deleteQuestion?postId=${data.postId}`, {
      headers: {
        Authorization: "bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
    });
    if (data.deleteConfirmation) {
      data.deleteConfirmation();
    }
    return { ...data };
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
