import React from 'react'
import './style.scss'
import logo192 from '../../images/logo192.png' 
export default function MainLoader() {
    return (
        <div className="tun-main-loader">
        <div className="blob setImage-dfd">
          <img className="m-auto"  src={logo192} alt="blob" />
        </div>
      </div>
    )
}
