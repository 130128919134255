import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { addAttendingInitial } from "./index";
export const addAttending = createAsyncThunk("Events/addAttending", async (data, thunkAPI) => {
  try {

    let attendingData = {
        postId: data._id,
        userId: data?.userDetail?._id,
      };

    let obj = {
      postId: data._id,
      userId: data.userDetail?._id,
      user: [
        {
          _id: data.userDetail?._id,
          firstName: data.userDetail?.firstName,
          lastName: data.userDetail?.lastName,
        },
      ],
      type:'attending'
    };
    thunkAPI.dispatch(addAttendingInitial(obj));
    const response = await axios.post(`${process.env.REACT_APP_API_BASE}/api/v1/user/tunApp/events/addAttending`, attendingData, {
      headers: {
        Authorization: "bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
