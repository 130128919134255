import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const deleteComment = createAsyncThunk("Events/deleteComment", async (data, thunkAPI) => {
  try {
    let commentData = {
      postId: data._id,
      commentId: data.commentId,
      userId: data.userDetail?._id,
    };
    const response = await axios.post(`${process.env.REACT_APP_API_BASE}/api/v1/user/tunApp/events/deleteComment`, commentData, {
      headers: {
        Authorization: "bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
    });
    if (data.deleteConfirmation) {
      data.deleteConfirmation();
    }
    return commentData;
  } catch (error) {
    if (error.response) {
      data.deleteError(error.response.data.message);
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      data.deleteError(error.messag);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
