import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const getDiscoverGroups = createAsyncThunk("groups/getDiscoverGroups", async (data, thunkAPI) => {
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_API_BASE}/api/v1/user/tunApp/groups/discoverGroup?userId=${data?.userId || ""}&communityId=${
        data?.communityId || ""
      }&page=${data?.filters?.page || 1}&limit=${data?.filters?.limit || 1}&search=${data?.filters?.search || ""}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
      }
    );

    return resp.data?.response;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
