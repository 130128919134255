import React,{Suspense} from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import MainLoader from "./components/loader/mainLoader";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";

const App = React.lazy(() => import("./App"));
/* import * as serviceWorker from './serviceWorker'; */
const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
// console.warn = console.error = () => {};
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
    <Suspense fallback={<MainLoader />}>
      <App />
      </Suspense>
    </PersistGate>
  </Provider>
);
// for progressive webapp
/* serviceWorker.register(); */

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
console.warn = console.error = () => {};
