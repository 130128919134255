import axios from "axios";
import { setAuthToken } from "../../utils/setAuthToken";
import { resetAuthState } from "./index";
export const logoutUser = () => async (dispatch) => {
  let token = localStorage.token;
  localStorage.clear();
  setAuthToken(false);
  dispatch(resetAuthState(false));
  if (token) {
    try {
      await axios.get(`${process.env.REACT_APP_API_BASE}/api/v1/user/tunApp/logout`, {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      });
    } catch (err) {
      console.log(err);
    }
  }
};
