import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { setAuthToken } from "../../utils/setAuthToken";
// ${process.env.REACT_APP_API_BASE}
export const userLogin = createAsyncThunk("auth/userLogin", async (data, thunkAPI) => {
  try {
    const resp = await axios.post(`${process.env.REACT_APP_API_BASE}/api/v1/user/tunApp/login`, data, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    let token = resp.data.user.token;
    if (token) {
      setAuthToken(token);
      localStorage.setItem("token", token);
    }
    if (data.handleSuccess) {
      data.handleSuccess(resp.data?.user);
    }
    return resp.data?.user;
  } catch (error) {
    if (error.response) {
      data.handleError(error.response.data.message);
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      data.handleError(error.message);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
