import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const addQuestion = createAsyncThunk("Questions/addQuestion", async (data, thunkAPI) => {
  try {
    let obj = {
        question: data.question,
        communityId: data.userDetail.communityId || '',
        user: data.userDetail._id || '',
        groupId: data.groupId ? data.groupId : "",
        organizationId: data.organizationId ? data.organizationId : "",
      };
    const response = await axios.post(`${process.env.REACT_APP_API_BASE}/api/v1/user/tunApp/questions/addQuestion`, obj, {
      headers: {
        Authorization: "bearer " + localStorage.token,
        "Content-Type": "application/json",
      },
    });
    if (data.handleSuccess) {
      data.handleSuccess();
    }
    return response.data.question;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
