import { createSlice } from "@reduxjs/toolkit";
import { getQuestions } from "./getQuestion";
import { addComment } from "./addComment";
import { addReplyOnComment } from "./addReplyOnComment";
import { updateComment } from "./updateComment";
import { deleteComment } from "./deleteComment";
import { updateReplyOnComment } from "./updateReply";
import { deleteReplyOnComment } from "./deleteReply";
import { addQuestion } from "./addQuestion";
import { UpdateQuestion } from "./updateQuestion";
import { deleteQuestion } from "./deleteQuestion";
const initialState = {
  questionsList: {
    isLoading: false,
    data: [],
    stats: {},
    success: false,
    errMsg: null,
  },
  addComment: {
    isLoading: false,
    data: {},
    success: false,
    errMsg: null,
  },
  updateComment: {
    isLoading: false,
    success: false,
    errMsg: null,
  },
  addLike: {
    isLoading: false,
    data: {},
    success: false,
    errMsg: null,
  },
  reply: {
    isLoading: false,
    success: false,
    errMsg: null,
  },
  updateReply: {
    isLoading: false,
    success: false,
    errMsg: null,
  },
  addQuestion: {
    isLoading: false,
    success: false,
    errMsg: null,
  },
  questionUpdate: {
    isLoading: false,
    success: false,
    errMsg: null,
  },
};
const Questions = createSlice({
  name: "Questions",
  initialState,
  reducers: {
    addLikeInitial: (state, action) => {
      let questions = [...state.questionsList.data];
      let index = questions.findIndex((item) => item._id === action.payload?.postId);
      if (action.payload.type === "add") {
        questions[index] = {
          ...questions[index],
          likes: [...questions[index].likes, action.payload],
        };
      } else {
        questions[index] = {
          ...questions[index],
          likes: questions[index].likes?.filter((item) => item.user[0]._id !== action.payload.userId),
        };
      }
      state.questionsList.data = questions;
    },
    addLikeOnCommentInitial: (state, action) => {
      let questions = [...state.questionsList.data];
      let index = questions.findIndex((item) => item._id === action.payload?.postId);
      let index1 = questions[index].comments.findIndex((item) => item._id === action.payload.commentId);
      if (action.payload.type === "add") {
        questions[index].comments[index1].likeBy = [...questions[index].comments[index1].likeBy, action.payload];
      } else {
        questions[index].comments[index1].likeBy = [...questions[index].comments[index1].likeBy].filter(
          (item) => item.userId !== action.payload.userId
        );
      }
      state.questionsList.data = questions;
    },
  },
  extraReducers: {
    // add Question
    [addQuestion.pending]: (state) => {
      state.addQuestion.isLoading = true;
      state.addQuestion.success = false;
      state.addQuestion.errMsg = null;
    },
    [addQuestion.fulfilled]: (state, action) => {
      state.addQuestion.isLoading = false;
      state.addQuestion.success = true;
      let questions = [...state.questionsList.data];
      questions.unshift(action.payload);
      state.questionsList.data = questions;
      state.questionsList.stats.totalDoc = state.questionsList.stats.totalDocs + 1;
    },
    [addQuestion.rejected]: (state, action) => {
      state.addQuestion.isLoading = false;
      state.addQuestion.success = false;
      state.addQuestion.errMsg = action.payload;
    },
    // update Question
    [UpdateQuestion.pending]: (state) => {
      state.questionUpdate.isLoading = true;
      state.questionUpdate.success = false;
      state.questionUpdate.errMsg = null;
    },
    [UpdateQuestion.fulfilled]: (state, action) => {
      state.questionUpdate.isLoading = false;
      state.questionUpdate.success = true;
      let questions = [...state.questionsList.data];
      let index = questions.findIndex((item) => item._id === action.payload?.postId);
      questions[index].question = action.payload.question;
      state.questionsList.data = questions;
    },
    [UpdateQuestion.rejected]: (state, action) => {
      state.questionUpdate.isLoading = false;
      state.questionUpdate.success = false;
      state.questionUpdate.errMsg = action.payload;
    },
    // questions
    [getQuestions.pending]: (state) => {
      state.questionsList.isLoading = true;
      state.questionsList.success = false;
      state.questionsList.errMsg = null;
    },
    [getQuestions.fulfilled]: (state, action) => {
      state.questionsList.isLoading = false;
      state.questionsList.success = true;
      state.questionsList.data = action.payload.docs;
      state.questionsList.stats = { ...action.payload, docs: null };
    },
    [getQuestions.rejected]: (state, action) => {
      state.questionsList.isLoading = false;
      state.questionsList.success = false;
      state.questionsList.errMsg = action.payload;
    },
    // add comment
    [addComment.pending]: (state) => {
      state.addComment.isLoading = true;
      state.addComment.success = false;
      state.addComment.errMsg = null;
    },
    [addComment.fulfilled]: (state, action) => {
      state.addComment.isLoading = false;
      state.addComment.success = true;
      let questions = [...state.questionsList.data];
      let index = questions.findIndex((item) => item._id === action.payload?.postId);
      questions[index].comments.unshift(action.payload);
      state.questionsList.data = questions;
    },
    [addComment.rejected]: (state, action) => {
      state.addComment.isLoading = false;
      state.addComment.success = false;
      state.addComment.errMsg = action.payload;
    },
    // update comment
    [updateComment.pending]: (state) => {
      state.updateComment.isLoading = true;
      state.updateComment.success = false;
      state.updateComment.errMsg = null;
    },
    [updateComment.fulfilled]: (state, action) => {
      state.updateComment.isLoading = false;
      state.updateComment.success = true;
      let questions = [...state.questionsList.data];
      let index = questions.findIndex((item) => item._id === action.payload?.postId);
      let findCommentIndex = questions[index].comments.findIndex((it) => it._id === action.payload.commentId);
      questions[index].comments[findCommentIndex].text = action.payload.text;
      state.questionsList.data = questions;
    },
    [updateComment.rejected]: (state, action) => {
      state.updateComment.isLoading = false;
      state.updateComment.success = false;
      state.updateComment.errMsg = action.payload;
    },
    // delete Question
    [deleteQuestion.fulfilled]: (state, action) => {
      let questions = [...state.questionsList.data];
      state.questionsList.data = questions.filter((item) => item._id !== action.payload.postId);
    },
    // delete comment
    [deleteComment.fulfilled]: (state, action) => {
      let questions = [...state.questionsList.data];
      let index = questions.findIndex((item) => item._id === action.payload?.postId);
      const commentData = questions[index].comments;
      const deleteCommentIndex = commentData.findIndex((item) => item._id === action.payload.commentId);
      commentData.splice(deleteCommentIndex, 1);
      state.questionsList.data = questions;
    },
    // delete Reply On Comment
    [deleteReplyOnComment.fulfilled]: (state, action) => {
      let questions = [...state.questionsList.data];
      let index = questions.findIndex((item) => item._id === action.payload?.postId);
      const commentData = questions[index].comments;
      const CommentIndex = commentData.findIndex((item) => item._id === action.payload.commentId);
      const findReplyIndex = commentData[CommentIndex].replyBy.findIndex((it) => it._id === action.payload.replyId);
      commentData[CommentIndex].replyBy.splice(findReplyIndex, 1);
      state.questionsList.data = questions;
    },
    // add reply on comment
    [addReplyOnComment.pending]: (state) => {
      state.reply.isLoading = true;
      state.reply.success = false;
      state.reply.errMsg = null;
    },
    [addReplyOnComment.fulfilled]: (state, action) => {
      state.reply.isLoading = false;
      state.reply.success = true;
      let questions = [...state.questionsList.data];
      let index = questions.findIndex((item) => item._id === action.payload?.postId);
      questions[index].comments.filter((item) => item._id === action.payload.commentId)[0].replyBy.unshift(action.payload);
      state.questionsList.data = questions;
    },
    [addReplyOnComment.rejected]: (state, action) => {
      state.addComment.isLoading = false;
      state.addComment.success = false;
      state.addComment.errMsg = action.payload;
    },
    // update reply on comment
    [updateReplyOnComment.pending]: (state) => {
      state.updateReply.isLoading = true;
      state.updateReply.success = false;
      state.updateReply.errMsg = null;
    },
    [updateReplyOnComment.fulfilled]: (state, action) => {
      state.updateReply.isLoading = false;
      state.updateReply.success = true;
      let questions = [...state.questionsList.data];
      let index = questions.findIndex((item) => item._id === action.payload?.postId);
      const findComment2 = questions[index].comments.filter((it) => it._id === action.payload.commentId)[0];
      const findReplyIndex2 = findComment2.replyBy.findIndex((it) => it._id === action.payload.replyId);
      findComment2.replyBy[findReplyIndex2].text = action.payload.text;
      state.questionsList.data = questions;
    },
    [updateReplyOnComment.rejected]: (state, action) => {
      state.updateReply.isLoading = false;
      state.updateReply.success = false;
      state.updateReply.errMsg = action.payload;
    },
  },
});

export const { addLikeInitial, addLikeOnCommentInitial } = Questions.actions;
export default Questions.reducer;
export * from "./getQuestion";
export * from "./addComment";
export * from "./addLike";
export * from "./undoLike";
export * from "./addLikeOnComment";
export * from "./dislikeComment";
export * from "./addReplyOnComment";
export * from "./updateComment";
export * from "./deleteComment";
export * from "./updateReply";
export * from "./deleteReply";
export * from "./addQuestion";
export * from "./updateQuestion";
export * from "./deleteQuestion";
