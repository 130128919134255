import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const getTrending = createAsyncThunk("HighSchoolPosts/getTrending", async (data, thunkAPI) => {
  try {
    const resp = await axios.get(`${process.env.REACT_APP_API_WORDPRESS_BASE}/blog/wp-json/wp/v2/posts?categories=676`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return resp.data;
  } catch (error) {
    if (error.response) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    } else {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});
