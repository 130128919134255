import { createSlice } from "@reduxjs/toolkit";
import { getPreferences } from "./getPreferences";
import { getGradeLevels } from "./getGradeLevels";
import { getSchools } from "./getSchools";
import {getColleges} from "./getColleges"

const initialState = {
  preferences: {
    isLoading: false,
    data: {},
    success: false,
    errMsg: null,
  },
  gradeLevels: {
    isLoading: false,
    data: {},
    success: false,
    errMsg: null,
  },
  schoolList: {
    isLoading: false,
    data: {},
    success: false,
    errMsg: null,
  },
  collegeList: {
    isLoading: false,
    data: {},
    success: false,
    errMsg: null,
  },
};

const globalData = createSlice({
  name: "globalData",
  initialState,
  extraReducers: {
    // grade levels
    [getGradeLevels.pending]: (state) => {
      state.gradeLevels.isLoading = true;
      state.gradeLevels.errMsg = null;
    },
    [getGradeLevels.fulfilled]: (state, action) => {
      state.gradeLevels.isLoading = false;
      state.gradeLevels.errMsg = null;
      state.gradeLevels.data = action.payload;
    },
    [getGradeLevels.rejected]: (state, action) => {
      state.gradeLevels.isLoading = false;
      state.gradeLevels.errMsg = action.payload;
    },
    // get preferences
    [getPreferences.pending]: (state) => {
      state.preferences.isLoading = true;
      state.preferences.errMsg = null;
    },
    [getPreferences.fulfilled]: (state, action) => {
      state.preferences.isLoading = false;
      state.preferences.errMsg = null;
      state.preferences.data = action.payload;
    },
    [getPreferences.rejected]: (state, action) => {
      state.preferences.isLoading = false;
      state.gradeLevels.errMsg = action.payload;
    },
      //  schools
      [getSchools.pending]: (state) => {
        state.schoolList.isLoading = true;
        state.schoolList.errMsg = null;
      },
      [getSchools.fulfilled]: (state, action) => {
        state.schoolList.isLoading = false;
        state.schoolList.errMsg = null;
        state.schoolList.data = action.payload;
      },
      [getSchools.rejected]: (state, action) => {
        state.schoolList.isLoading = false;
        state.schoolList.errMsg = action.payload;
      },
      // colleges
      [getColleges.pending]: (state) => {
        state.collegeList.isLoading = true;
        state.collegeList.errMsg = null;
      },
      [getColleges.fulfilled]: (state, action) => {
        state.collegeList.isLoading = false;
        state.collegeList.errMsg = null;
        state.collegeList.data = action.payload;
      },
      [getColleges.rejected]: (state, action) => {
        state.collegeList.isLoading = false;
        state.collegeList.errMsg = action.payload;
      }
  },
});

export default globalData.reducer;
export * from "./getGradeLevels";
export * from "./getPreferences";
export * from "./getColleges";
export * from "./getSchools"
